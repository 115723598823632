import { predictedJobs } from "./predictedJobs";
import * as XLSX from "xlsx";
import ResultReportTable from "../components/PDFTableReport";
import { pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import ResultReport from "../components/PDFReport";

export const calculateResults = (adminData, quiz, under18Ver, users) => {
    let att = [...new Set(quiz.questions.map(q => q.attribute))]

    let userReportData = [];
    users.forEach((user) => {
        if(
            (!user.results.find(r => r.quizId === quiz._id) 
            || !user.results.find(r => r.quizId === quiz._id)?.date_completed)
            && (!user.results.find(r => r.quizId === under18Ver._id)
            || !user.results.find(r => r.quizId === under18Ver._id)?.date_completed)
        ){
            userReportData.push({email: user.email, userId: user._id, name: user.name, scores: att.map((at) => "N/A")})
            return;
        }
        let scores = att.map((el) => [])
        let questionnaire = user?.extra_data?.ageRange === "14 - 18" ? under18Ver : quiz;
        user.results.find(r => r.quizId === questionnaire._id).answers.forEach((answer) => {
            let questionObj = questionnaire.questions.find(q => q._id === answer.question_id);
            let chosenAnsObj = questionObj.answers.find(ans => ans._id === answer.answer_id);
            scores[att.indexOf(questionObj.attribute)].push(chosenAnsObj.points);
        })
        userReportData.push({email: user.email, userId: user._id, name: user.name, scores: scores.map((att) => (att.reduce(( p, c ) => p + c, 0 ) / att.length).toFixed(1))})
    })
    return {compName: adminData.company, quizName: quiz.title, usersData: userReportData, attributes: att }
}

export const getPDFTable = async (adminData, quiz, under18Ver, users) => {
    const props = await calculateResults(adminData, quiz, under18Ver, users);
    const doc = <ResultReportTable {...props} />;
    const asPdf = pdf([]);
    asPdf.updateContainer(doc);
    const blob = await asPdf.toBlob();
    saveAs(blob, 'digiDNA-table-report.pdf');
}

export const getPDFCharts = async (images) => {
    const doc = <ResultReport images={images} />;
    const asPdf = pdf([]);
    asPdf.updateContainer(doc);
    const blob = await asPdf.toBlob();
    saveAs(blob, 'digiDNA-report.pdf');
}

export const getCSVData = (adminData, quiz, under18Ver, users) => {

    let data = calculateResults(adminData, quiz, under18Ver, users)
    let csvData = []

    csvData.unshift([`digiDNA ${data.quizName} Results for ${adminData.company}`])

    csvData.push(["Forename", "Surname", "Email", "Is Registered", "Is Verified", "Marketing Consented", ...data.attributes, "Total", "Matched Jobs", "", "", "Age Range", "Gender", "Highest Education", "Prev. Tech Skills", "Employment Status", "Region", "Neurodiversity", "Ethnicity", "Aspirations", "Where did you hear about us?", "Skills"])
    let extraDataOptions = ["ageRange", "gender", "education", "prevTechSkill", "status", "region", "neurodiversity", "ethnicity", "aspiration", "whereDidYouHear"]
    
    data.usersData.forEach(user => {
        let userData = users.find(u => u._id === user.userId)
        let jobs = user.scores.includes("N/A") ? [{job:"N/A"}, {job:"N/A"}, {job:"N/A"}] : predictedJobs(data.attributes, user.scores.map(s => parseFloat(s)))
        let extraInfo = [];
        if(userData.registered && userData.extra_data) {
            extraDataOptions.forEach(t => {
                if(t === "region") {
                    (userData.extra_data[t]) ? extraInfo.push(userData.extra_data[t]) : extraInfo.push(userData.extra_data.regionNW)
                    return;
                }
                if(userData.extra_data[t]){
                    extraInfo.push(userData.extra_data[t])
                } else {
                    extraInfo.push("N/A")
                }
            })
        } else {
            extraInfo.push("N/A","N/A","N/A","N/A","N/A","N/A","N/A","N/A","N/A","N/A")
        }
        csvData.push([
            user.name?.split(",")[0],
            user.name?.split(",")[1],
            user.email,
            (userData?.registered) ? "yes" : "no",
            (userData?.isVerified) ? "yes" : "no",
            (userData?.marketing) ? "yes" : "no",
            ...user.scores,
            user.scores.includes("N/A") ? "N/A" : user.scores.reduce((a, b) => parseInt(a) + parseInt(b)),
            ...jobs.map(j => j.job),
            ...extraInfo,
            userData.extra_data?.skills ? userData.extra_data.skills.join(", ") : "N/A"])
    })

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.aoa_to_sheet(csvData);

    XLSX.utils.book_append_sheet(wb, ws, "Questionnaire Results");

    XLSX.writeFile(wb, "digiDNA-results.xlsx");
}
