import styled from "styled-components";
import Table from "./Table";
import search from "../../assets/icons/search-icon.png";
import smallArrow from "../../assets/icons/small-arrow.png";
import xIcon from "../../assets/icons/x.png";
import downloadIcon from "../../assets/icons/download.png";
import { useAdmin } from "../../context/AdminContext";
import TableToolBar from "./TableToolBar";
import TableGroups from "./TableGroups";
import { device } from "../../styles/Responsive.style";
import { useState } from "react";
import { getCSVData, getPDFCharts, getPDFTable } from "../../utils/downloads";

const TableSection = () => {
    const [openDownloadOptions, setOpenDownloadOptions] = useState(false);
    const { allUserData, displayUsers, setDisplayUsers, currentPage, setCurrentPage, usersPerPage, adminData, allQuizzes, selectedQuizId, setGenerateSpiders } = useAdmin();

    const searchName = (e) => {
        if (e.target.value.length > 0) {
            setDisplayUsers(allUserData.filter(user => user.name.toLowerCase().match(e.target.value.toLowerCase().trim())))
        } else {
            setDisplayUsers(allUserData)
        }
    }

    return (
        <TableSectionWrapper>
            <TableGroups />
            <TableInteractions>
                <LeftSide>
                    <p>Showing {((currentPage - 1) * usersPerPage) + 1} - {(currentPage * usersPerPage > displayUsers.length) ? displayUsers.length : currentPage * usersPerPage} of {displayUsers.length}</p>
                    <SearchBox>
                        <input type="search" onChange={(e) => searchName(e)} id="searchName" placeholder="Search Name" />
                        <label htmlFor="searchName"><img src={search} className="black-icon" alt="search icon" /></label>
                    </SearchBox>
                </LeftSide>
                <RightSide>
                    <section onClick={() => setOpenDownloadOptions(!openDownloadOptions)}>
                        <DownloadDropDown className={openDownloadOptions ? "in-front" : ""}>
                            <DownloadBtn className={openDownloadOptions ? "open-btn" : ""}><img src={downloadIcon} className="black-icon" alt="download icon" />Download Group</DownloadBtn>
                            <DropdownOptions className={openDownloadOptions ? "open" : ""}>
                                <button onClick={() => getPDFTable(adminData, allQuizzes.find(q => q._id === selectedQuizId), (allQuizzes.find(aq => aq.title.includes(allQuizzes.find(q => q._id === selectedQuizId).title) && aq.forUnder18s)) ? allQuizzes.find(aq => aq.title.includes(allQuizzes.find(q => q._id === selectedQuizId).title) && aq.forUnder18s) : null, displayUsers)}>as PDF - Table</button>
                                <button onClick={() => setGenerateSpiders(true)}>as PDF - Radars</button>
                                <button onClick={() => getCSVData(adminData, allQuizzes.find(q => q._id === selectedQuizId), (allQuizzes.find(aq => aq.title.includes(allQuizzes.find(q => q._id === selectedQuizId).title) && aq.forUnder18s)) ? allQuizzes.find(aq => aq.title.includes(allQuizzes.find(q => q._id === selectedQuizId).title) && aq.forUnder18s) : null , displayUsers)}>as XLSX</button>
                            </DropdownOptions>
                        </DownloadDropDown>
                        <Screen className={openDownloadOptions ? "open-screen" : ""} />
                    </section>
                    <MovePageBtns>
                        <button className="prevBtn" onClick={() => currentPage !== 1 ? setCurrentPage(currentPage - 1) : null}>
                            <img src={smallArrow} className="black-icon" alt="arrow pointing left" />
                        </button>
                        <button className="nextBtn" onClick={() => currentPage !== Math.ceil(displayUsers.length / usersPerPage) ? setCurrentPage(currentPage + 1) : null}>
                            <img src={smallArrow} className="black-icon" alt="arrow pointing right" />
                        </button>
                    </MovePageBtns>
                </RightSide>
            </TableInteractions>

            <Table />

            <TableToolBar />
        </TableSectionWrapper>
    )
}

export default TableSection;

const TableSectionWrapper = styled.section`
    padding: 10px;
    box-shadow: 0 2px 10px 0 var(--gray-light);
    border-radius: 10px;
`;

const TableInteractions = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 15px;
    margin-bottom: 10px;

    p {
        font-size: var(--font-small);
        background-color: var(--bg-main-light);
        margin: 0;
        padding: 10px 20px;
        border-radius: 5px;
    }

    @media ${device.medium} {
        flex-direction: column;
        align-items: flex-end;
    }
`;

const LeftSide = styled.div`
    display: flex;
    gap: 15px;

    @media ${device.medium} {
        width: 100%;
    }

    @media ${device.small} {
        flex-direction: column;
    }
`;

const RightSide = styled.div`
    display: flex;
    gap: 15px;

    @media ${device.small} {
        width: 100%;
        justify-content: space-between;
    }
`;

const DownloadDropDown = styled.div`
    position: relative;
    z-index: 4;
    height: 100%;

    &.in-front {
        z-index: 10;
    }
`;

const DropdownOptions = styled.div`
    display: none;
    flex-direction: column;
    position: absolute;
    width: 100%;
    box-shadow: 0 2px 10px 0 var(--gray-light);
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    z-index: 18;

    button {
        text-align: left;
        background-color: var(--bg-main);
        border: 1px solid var(--gray-light);
        border-top: none;
        font-size: var(--font-small);
        padding: 10px 15px;
        cursor: pointer;
        transition: background-color 0.2s;

        &:hover {
            background-color: var(--bg-main-light);
        }

        &:last-child {
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
        }
    }

    &.open {
        display: flex;
    }
`;

const DownloadBtn = styled.button`
    border: 1px solid var(--gray-light);
    display: flex;
    background-color: var(--bg-main);
    align-items: center;
    padding: 0 15px;
    border-radius: 5px;
    font-weight: 900;
    font-size: var(--font-small);
    cursor: pointer;
    transition: background-color 0.2s;
    height: 100%;

    &:hover {
        background-color: var(--bg-main-light);
    }

    img {
        height: 25px;
        margin-right: 5px;
    }

    &.open-btn {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    @media ${device.medium} {
        padding: 7px 15px;
    }
`;

const Screen = styled.div`
    position: fixed;
    height: 0vh;
    width: 100vw;
    top: 0px;
    left: 0px;

    &.open-screen {
        height: 100vh;
        z-index: 5;
    }
`;

const SearchBox = styled.div`
    border: 1px solid var(--gray-light);
    border-radius: 5px;
    overflow: hidden;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;

    img {
        height: 18px;
        padding: 0 5px 0 15px;
        opacity: 0.4;
        transition: opacity 0.2s;
    }

    label {
        display: flex;
        align-items: center;
        cursor: text;
    }
    
    input {
        border: none;
        font-size: var(--font-small);
        padding: 10px;
        background-color: var(--bg-main);

        &:focus {
            outline: none;
        }

        &::-webkit-search-cancel-button {
            -webkit-appearance: none;
            height: 20px;
            width: 20px;
            background: url(${xIcon});
            background-size: contain;
        }
    }

    input:focus + label img {
        opacity: 1;
    }

    @media ${device.medium} {
        flex: 1;
        justify-content: flex-end;
    }
`;

const MovePageBtns = styled.div`
    display: flex;

    button {
        border: 1px solid var(--gray-light);
        background-color: transparent;
        display: flex;
        align-items: center;
        padding: 0 10px;
        cursor: pointer;
        transition: background-color 0.2s;

        &.prevBtn {
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
            border-right: none;
        }
        &.nextBtn {
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
        }

        &:hover {
            background-color: var(--bg-main-light);
        }
    }

    img {
        height: 15px;
    }

    .prevBtn img {
        transform: rotate(90deg);
    }
    .nextBtn img {
        transform: rotate(-90deg);
    }
`;