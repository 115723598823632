import PageTransition from "../components/PageTransition.js";
import { useEffect, useState, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import QuestionCard from "../components/QuestionCard.js";
import { PrimaryButton } from "../styles/Button.styles.js";
import styled from "styled-components";
import { completeQuizAnswers, getGroupRegister, getSingleQuiz, saveQuizAnswers } from "../utils/index.js";
import IncompleteAnswers from "../components/Modals/IncompleteAnswers.js";
import { device } from "../styles/Responsive.style.js";
import { useUser } from "../context/UserContext.js";
import { pdf } from "@react-pdf/renderer";
import SummaryReport from "../components/PDFResultSummary.js";
import { predictedJobs } from "../utils/predictedJobs.js";
import SpiderChart from "../components/SpiderChart.js";

const Quiz = () => {
    const [quizData, setQuizData] = useState({});
    const [questionSections, setQuestionSections] = useState([]);
    const [activeSection, setActiveSection] = useState(0);
    const [answers, setAnswers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [spiderChartData, setSpiderCharData] = useState(false);
    const [isSpecialAccount, setIsSpecialAccount] = useState(false);

    const questionsTop = useRef();

    const [incompleteModal, setIncompleteModal] = useState(false);

    let { id } = useParams();
    const navigate = useNavigate();
    const { userName } = useUser();

    const shuffleArray = (passedQuiz) => {
        for(let k = 0; k < passedQuiz.questions.length; k++){
            for (let i = passedQuiz.questions[k].answers.length - 1; i > 0; i--) {
                const j = Math.floor(Math.random() * (i + 1));
                [passedQuiz.questions[k].answers[i], passedQuiz.questions[k].answers[j]] = [passedQuiz.questions[k].answers[j], passedQuiz.questions[k].answers[i]];
            }
        }
        return passedQuiz;
    };

    const handleInitialFetch = async () => {
        let data = await getSingleQuiz(id);
        let shuffledAnswers = shuffleArray(data.quiz);
        let special = await getGroupRegister(null);
        setIsSpecialAccount(special.specialAccount);
        let updatedQuiz = {
            ...shuffledAnswers,
            title: data.quiz.title === "General Questionnaire" && special.specialAccount === "cyberLocal" ? "Cyber Questionnaire" : data.quiz.title
        }
        setQuizData(updatedQuiz);
        
        if(data.quiz.archived) {
            navigate('/questionnaires')
        }
       
        // create section list on the left and what question should be visible
        let sectionSize = 11;
        let questions = [];
        for (let i = 0; i < data.quiz.questions.length; i += sectionSize) {
            const section = data.quiz.questions.slice(i, i + sectionSize);
            questions.push(section)
        }
        setQuestionSections(questions)

        // sort answers already filled in
        let emptyAnswers = data.quiz.questions.map((question) => {
            return {
                question_id: question._id,
                answer_id: null,
                point: 0
            }
        });

        data.userAnswers.forEach((ans) => {
            let index = emptyAnswers.findIndex((question) => question.question_id === ans.question_id);
            if(index === -1) return;
            emptyAnswers[index] = ans;
        })

        setAnswers(emptyAnswers)
        setIsLoading(false);
    }

    useEffect(() => {
        handleInitialFetch()
    }, [])

    useEffect(() => {
        if(spiderChartData.image){
            const sendResAndPDF = async () => {
                let pdfData = {
                    name: userName, 
                    dateCompleted: new Date().toLocaleDateString("en-GB", {year: "numeric", month: "short", day:"numeric"}),
                    title: quizData.title,
                    attributes: spiderChartData.attribute,
                    scores: spiderChartData.avgScores,
                    jobs: predictedJobs(spiderChartData.attribute, spiderChartData.avgScores),
                    image: spiderChartData.image
                }
                const doc = <SummaryReport summaryData={pdfData}/>;
                const asPdf = pdf([]);
                asPdf.updateContainer(doc);
                const blob = await asPdf.toBlob();
                const base64String = await new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.onloadend = () => resolve(reader.result.split(",")[1]);
                    reader.onerror = reject;
                    reader.readAsDataURL(blob)
                })
                let url = `data:application/pdf;base64,${base64String}`
                await completeQuizAnswers(quizData._id, answers, {pdfBase64Link: url, recommendedJobs: pdfData.jobs.map(j => j.job) });
                navigate(`/results/${quizData._id}`);
            }
            sendResAndPDF()
        }
    }, [spiderChartData])
    
    const completeQuiz = async (e) => {
        e.preventDefault();

        if(answers.filter((answer) => answer.answer_id === null).length > 0){
            setIncompleteModal(true);
            return;
        }

        if(isSpecialAccount === "cyberLocal") {
            const attributes = [...new Set(quizData.questions.map(q => q.attribute))];
            let scores = attributes.map((el) => []);
            answers.forEach((answer) => {
                let questionObj = quizData.questions.find(q => q._id === answer.question_id);
                let chosenAnsObj = questionObj.answers.find(ans => ans._id === answer.answer_id);
                scores[attributes.indexOf(questionObj.attribute)].push(chosenAnsObj.points);
            });

            let avgScores = scores.map((att) => (att.reduce(( p, c ) => p + c, 0 ) / att.length).toFixed(1));
            
            setSpiderCharData({ attribute: attributes.map((att,i) => {if(i === 0 || i ===5) return att;let a = att.split("&"); if(a.length > 1)a[0] += " &"; return a}), avgScores: avgScores})
            return;
        }

        await completeQuizAnswers(quizData._id, answers, null);
        navigate(`/results/${quizData._id}`);
    }

    const nextSection = (e) => {
        e.preventDefault();
        questionsTop.current?.scrollIntoView({behaviour: 'smooth'})
        setActiveSection(activeSection + 1);
    }

    const handleAnswerChange = async (questionId, answer, points) => {
        let answersCopy = [...answers];
        let index = answersCopy.findIndex(question => question.question_id === questionId)
        answersCopy[index].answer_id = answer;
        answersCopy[index].point = points;
        setAnswers(answersCopy);

        if(questionSections[activeSection].map(q => answers.find(a => a.question_id === q._id).answer_id === null).indexOf(true) === -1){
            await saveQuizAnswers(quizData._id, answers);
        }
        
    }

    return (
        <QuizWrapper>

            {spiderChartData && <SpiderChart categories={spiderChartData.attribute} pointRange={{min: 0, max: 10}} series={[{name: quizData.title, data: spiderChartData.avgScores}]} title={`${userName}'s digiDNA Attributes`} setSummaryData={setSpiderCharData} forDoc={true}/>}

            <TextHeader>
                <h1>{quizData.title}</h1>
                <p>{quizData.description}</p>
            </TextHeader>

            <Main>

                <Sidebar>
                    {questionSections.map((section, index) => {
                        return (
                            <button 
                                key={index} 
                                id={index === activeSection ? "active" : ""} 
                                className={answers.slice(index * questionSections[0].length, questionSections[0].length + (index * questionSections[0].length)).filter(ans => ans.answer_id !== null).length === questionSections[index].length ? "complete" : ""} 
                                onClick={() => {setActiveSection(index);questionsTop.current?.scrollIntoView({behaviour: 'smooth'})}}
                            >Section {index + 1}</button>
                        )
                    })}
                </Sidebar>

                <form ref={questionsTop}>
                    {questionSections[activeSection]?.map((question, index) => {
                        return(
                            <QuestionCard 
                                key={index} 
                                questionNum={index + 1} 
                                totalQuestions={questionSections[activeSection].length} 
                                questionInfo={question} 
                                savedAnswerId={answers[index + (questionSections[0].length * activeSection)].answer_id} 
                                changeAns={handleAnswerChange} 
                                isDisabled={false}
                            />
                        )
                    })}
                    {activeSection + 1 === questionSections.length ? (
                        <PrimaryButton onClick={completeQuiz}>Complete Questionnaire</PrimaryButton>
                    ) : (
                        <PrimaryButton onClick={nextSection}>Save Section</PrimaryButton>
                    )}
                </form>
            </Main>

            <IncompleteAnswers showModal={incompleteModal} setShowModal={setIncompleteModal} />

            <PageTransition isLoading={isLoading} />
        </QuizWrapper>
    )
}

export default Quiz;

const QuizWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 0 25px;
`;

const TextHeader = styled.header`
   width: 100%;
   max-width: 1500px;
`;

const Main = styled.main`
    display: flex;
    align-items: flex-start;
    max-width: 1500px;
    width: 100%;

    form {
        margin: 0;
        flex-grow: 1;
        
        & > button {
            margin: 25px 25px;
        }
    }

    @media ${device.small} {
        flex-direction: column;
    }
`;

const Sidebar = styled.aside`
    position: sticky;
    top: 25px;
    margin: 25px 0 0 0;
    width: 250px;
    min-width: 140px;
    height: auto;
    background-color: var(--primary-gray);
    border-radius: 10px;
    padding: 10px;
    box-sizing: border-box;

    button:not(:last-child) {
        border-bottom: 1px solid var(--secondary-gray);
    }

    button {
        width: 100%;
        text-align: left;
        padding: 10px;
        font-size: var(--font-small);
        border-radius: 0px;
        border: none;
        background-color: var(--primary-gray);
        cursor: pointer;
        position: relative;
    }

    #active {
        background-color: var(--secondary-gray);
    }

    .complete::before {
        position: absolute;
        content: '';
        height: 25px;
        width: 25px;
        background-color: var(--accent);
        border-radius: 50%;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
    }
      
    .complete::after {
        position: absolute;
        content: '';
        height: 10px;
        width: 4px;
        border-bottom: 4px solid white;
        border-right: 4px solid white;
        right: 18px;
        top: calc(50% - 1px);
        transform: translateY(-50%) rotate(45deg);
    }

    @media ${device.small} {
        position: static;
        width: 100%;
    }
`;