import { useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom";
import { getUserEmail, requestResendVerifyEmail, verifyUserEmail } from "../utils";
import styled from "styled-components";
import PageTransition from "../components/PageTransition";
import { useUser } from "../context/UserContext";
import OTPInput from "../components/OTPInput";
import Cookies from "universal-cookie";

const cookies = new Cookies();

const VerifyEmail = () => {
    const [userEmail, setUserEmail] = useState("");
    const [errorMsg, setErrorMsg] = useState("");
    const { setShowContactForm } = useUser();

    const navigate = useNavigate();
    const fetchEmail = async () => {
        const res = await getUserEmail();
        if(res.email){
            setUserEmail(res.email)
        }
    }

    const checkVerifyCode = async (code) => {
        const res = await verifyUserEmail(code);

        if(res.message === "Invalid or expired token") {
            setErrorMsg("Invalid or expired token")
        } else if (res.message === "Email verified successfully") {
            await cookies.set('token', res.newToken, { path: '/' });
            navigate("/questionnaires")
        }
    }

    useEffect(() => {
        fetchEmail()
    }, [])

    const resendEmail = async () => {
        const res = await requestResendVerifyEmail();

        if(res.message === "Too soon"){
            setErrorMsg("You can only resend an email every minute. Please wait before resending again.")
        } else if(res.message === "Email resent") {
            setErrorMsg("A new email has been sent.")
        } else {
            setErrorMsg("")
        }
    }

    return (
        <>
            <Instructions>
                <h1>Verify Email</h1>
                <p>Enter the 6-digit code sent to this address: <b>{userEmail}</b></p>
                <p>Is this email wrong? <BlueLink to="/changeEmail">Change email</BlueLink></p>
                <OTPInput onComplete={checkVerifyCode} />
                {errorMsg === "A new email has been sent." ? (
                    <SuccessText>{errorMsg}</SuccessText>
                ) : (
                    <ErrorText>{errorMsg}</ErrorText>
                )}
                <p>Not recieved an email? <BlueBtn onClick={resendEmail}>Resend code</BlueBtn></p>
                <p>Still struggling to verify? <BlueBtn onClick={() => setShowContactForm(true)}>Get in touch</BlueBtn></p>
            </Instructions> 
            <PageTransition isLoading={false}/>
        </>
    )
}

export default VerifyEmail;

const Instructions = styled.div`
    text-align: center;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 0 30px;
    box-sizing: border-box;

    h1, p {
        margin: 10px 0;
    }

    h1 {
        font-size: var(--font-medium);
    }

    p, .subHeading {
        font-size: var(--font-small);
    }
`;

const BlueLink = styled(Link)`
    color: var(--blue-dark);
    text-decoration: none;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
`;

const BlueBtn = styled(Link)`
    color: var(--blue-dark);
    text-decoration: none;
    cursor: pointer;
    border: none;
    padding: 0;
    display: inline-block;
    margin: 0;

    &:hover {
        text-decoration: underline;
    }
`;

const SuccessText = styled.p`
    color: var(--green-dark);
    height: 1em;
    margin: 0;
`;

const ErrorText = styled.p`
    color: var(--red-dark);
    height: 1em;
    margin: 0;
`;