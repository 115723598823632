import { Routes, Route, useLocation, Navigate } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import PrivateRoute from './components/PrivateRoute.js';

import Home from "./pages/Home.js";
import Login from './pages/Login.js';
import Quizzes from './pages/Quizzes.js';
import AdminDash from './pages/AdminDash.js';
import SupAdminDash from './pages/SupAdminDash.js';
import Quiz from './pages/Quiz.js';
import Results from './pages/Results.js';
import AdminResults from './pages/AdminResults.js';
import Layout from './components/Layout.js';
import ForgotPassword from './pages/ForgotPassword.js';
import ResetPassword from './pages/ResetPassword.js';
import Register from './pages/Register.js';
import Invite from './pages/Invite.js';
import { AdminProvider } from './context/AdminContext.js';
import ScrollToTop from './components/ScrollToTop.js';
import PrivacyNotice from './pages/PrivacyNotice.js';
import VerifyEmail from './pages/VerifyEmail.js';
import { useUser } from './context/UserContext.js';
import { useEffect } from 'react';
import Cookies from "universal-cookie";
import jwtDecode from "jwt-decode";
import usePrefersDarkMode from './utils/userPrefersDarkMode.js';
import ChangeEmail from './pages/ChangeEmail.js';

const cookies = new Cookies();

const App = () => {
  const location = useLocation();

  const { setUserName, isDarkMode, setIsDarkMode } = useUser();
  const systemDarkMode = usePrefersDarkMode();

  useEffect(() => {
      setIsDarkMode(systemDarkMode)
      const token = cookies.get("token");
      if(token !== undefined){
        const decoded = jwtDecode(token);
        setUserName(decoded.name ? decoded.name.split(",").join("") : decoded.name)
      }
  }, [])

  useEffect(() => {
    if(isDarkMode === "light") {
      document.documentElement.classList.add("dark")
      document.documentElement.classList.remove("light")
    } else if (isDarkMode === "dark") {
      document.documentElement.classList.add("light")
      document.documentElement.classList.remove("dark")
    }
  }, [isDarkMode])
  
  return (
    <>
      <ScrollToTop>
        <AnimatePresence mode="wait" initial={false}>
          <Routes location={location} key={location.pathname}>
            <Route path="/" element={ <Layout><Home /></Layout> } />
            <Route path="/privacyNotice" element={ <Layout><PrivacyNotice /></Layout> } />
            <Route path="/login" element={ <PrivateRoute state="loggedIn" skipTokenCheck><Layout><Login /></Layout></PrivateRoute> } />
            <Route path="/forgotPassword" element={ <Layout><ForgotPassword /></Layout> } />
            <Route path="/passwordReset" element={ <Layout><ResetPassword /></Layout> } />
            <Route path="/register" element={<Layout><Register /></Layout>} />
            <Route path="/invite/:companyId/:groupId" element={ <PrivateRoute state="loggedIn"><Layout><Invite /></Layout></PrivateRoute> } />
            <Route path="/join" element={ <Navigate to="/invite/6d60cafd-7d1a-4ba8-87eb-b1c773cf3776/67975916d5bd89aed03abbd2" replace />} />
            <Route path="/verifyEmail" element={
              <PrivateRoute roles={["User", "Admin", "SuperAdmin"]} skipVerification>
                <Layout>
                  <VerifyEmail /> 
                </Layout>
              </PrivateRoute> 
            } />
            <Route path="/changeEmail" element={
              <PrivateRoute roles={["User", "Admin", "SuperAdmin"]} skipVerification>
                <Layout>
                  <ChangeEmail /> 
                </Layout>
              </PrivateRoute> 
            } />
            <Route path="/questionnaires" element={
              <PrivateRoute roles={["User", "Admin", "SuperAdmin"]}>
                <Layout>
                  <Quizzes /> 
                </Layout>
              </PrivateRoute> 
            } />
            <Route path="/questionnaires/:id/:name" element={
              <PrivateRoute roles={["User", "Admin", "SuperAdmin"]}>
                <Layout>
                  <Quiz /> 
                </Layout>
              </PrivateRoute> 
            } />
            <Route path="/adminDash" element={
              <PrivateRoute roles={["Admin"]}>
                <Layout>
                  <AdminProvider>
                    <AdminDash /> 
                  </AdminProvider>
                </Layout>
              </PrivateRoute> 
            } />
            <Route path="/supAdminDash" element={
              <PrivateRoute roles={["SuperAdmin"]}>
                <Layout>
                  <SupAdminDash /> 
                </Layout>
              </PrivateRoute> 
            } />
            <Route path="/results/:quizId" element={
              <PrivateRoute roles={["User", "Admin", "SuperAdmin"]}>
                <Layout>
                  <Results /> 
                </Layout>
              </PrivateRoute> 
            } />
            <Route path="/results/:quizId/:userId" element={
              <PrivateRoute roles={["Admin"]}>
                <Layout>
                  <AdminResults />
                </Layout> 
              </PrivateRoute> 
            } />
          </Routes>
        </AnimatePresence>
      </ScrollToTop>
    </>
  );
}

export default App;
