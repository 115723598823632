import { useRef, useState } from "react";
import styled from "styled-components";

const OTPInput = ({ onComplete }) => {
    const numInputs = 6;
    const [values, setValues] = useState(Array(numInputs).fill(''));
    const inputsRef = useRef([]);

    const handleChange = (e, index) => {
        const val = e.target.value;
        if (val && !/^\d$/.test(val)) return;
        
        const newValues = [...values];
        newValues[index] = val;
        setValues(newValues);
        
        if (val && index < numInputs - 1) {
            inputsRef.current[index + 1].focus();
        }
        
        if (newValues.every((digit) => digit !== '')) {
            onComplete(newValues.join(''));
        }
    };

    const handleKeyDown = (e, index) => {
        if (e.key === 'Backspace' && !values[index] && index > 0) {
            inputsRef.current[index - 1].focus();
        }
    };

    const handlePaste = (e) => {
        e.preventDefault();
        const pasted = e.clipboardData.getData('Text').trim();
        if (/^\d{6}$/.test(pasted)) {
            const newValues = pasted.split('');
            setValues(newValues);
            newValues.forEach((digit, i) => {
                if (inputsRef.current[i]) {
                    inputsRef.current[i].value = digit;
                }
            });
            inputsRef.current[numInputs - 1].focus();
            onComplete(pasted);
        }
    };

    return (
        <OTPWrapper role="group" aria-label="Verification code">
            {values.map((value, index) => (
                <OTPInputBox
                    key={index}
                    type="text"
                    inputMode="numeric"
                    pattern="\d*"
                    maxLength="1"
                    value={value}
                    onChange={(e) => handleChange(e, index)}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                    onPaste={index === 0 ? handlePaste : () => {inputsRef.current[index].value = ""}}
                    ref={(el) => (inputsRef.current[index] = el)}
                    aria-label={`OTP Digit ${index + 1}`}
                />
            ))}
        </OTPWrapper>
    );
};

export default OTPInput;

const OTPWrapper = styled.div`
    display: flex;
    gap: 4px;
    justify-content: center;
`;

const OTPInputBox = styled.input`
    color: var(--text-main);
    font-size: 32px;
    width: 1em;
    padding: 0.2em;
    background-color: var(--primary-gray);
    text-align: center;
    border-radius: 5px;
    border: 1px solid var(--dark-bg-hover);

    &:focus {
        outline: none;
        border: 1px solid var(--accent);
        box-shadow: 0 0 0 1px var(--accent);
    }
`;